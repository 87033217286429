







































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import {
  AdjustmentStatus,
  ALL_DATA_ADJUSTMENT_PAGINATION,
  Utils,
} from '@/app/infrastructures/misc'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import controller from '@/app/ui/controllers/AdjustmentParcelPoinController'
import { DataObject } from '@/app/ui/components/DataTableV2/type'
import { Dictionary } from 'vue-router/types/router'

export interface IAllDataPoinParams {
  sortBy: string
  phoneNumber: string
  page: number | string
  perPage: string | number
  status: string
}

@Component({
  components: {
    LoadingOverlay,
    TextInput,
    DropdownSelect,
    DataTableV2,
    Badge,
    EmptyState,
    PaginationNav,
  },
})
export default class AllDataAdjustmentPoin extends Vue {
  sortOptions = [
    { label: 'Terbaru', value: 'NEWEST' },
    { label: 'Terlama', value: 'OLDEST' },
  ]
  filterOptions = [
    { label: 'Semua', value: 'SHOWALL' },
    { label: 'Approved', value: 'APPROVED' },
    { label: 'Rejected', value: 'REJECTED' },
    { label: 'Requested', value: 'REQUESTED' },
  ]
  parameters = {
    sortBy: this.sortOptions[0],
    status: this.filterOptions[0],
    page: 1,
    perPage: ALL_DATA_ADJUSTMENT_PAGINATION,
    phoneNumber: '',
  }
  controller = controller
  headers = [
    {
      title: 'Customer ID',
    },
    {
      title: 'Phone Number',
    },
    {
      title: 'Total Poin',
    },
    {
      title: 'Request Date',
    },
    {
      title: 'Requestor',
    },
    {
      title: 'Aprroval',
    },
    {
      title: 'Status',
    },
    {
      title: 'Action',
    },
  ]

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        sortBy:
          this.sortOptions.find(item => item.value === queries.sortBy) ||
          this.sortOptions[0],
        status:
          this.filterOptions.find(
            item => item.value === queries.status
          ) || this.filterOptions[0],
        page: Utils.alwaysNumber(queries.page) || 1,
        perPage: ALL_DATA_ADJUSTMENT_PAGINATION,
        phoneNumber: decodeURIComponent(queries.phoneNumber || ''),
      }
    }

    this.fetchAllDataAdjustment(true)
  }

  private onlyInputPhone(e: string): void {
    this.parameters.phoneNumber = Utils.phoneFormat(e)
  }

  get params(): IAllDataPoinParams {
    return {
      ...this.parameters,
      sortBy: this.parameters.sortBy.value,
      status: this.parameters.status.value,
      phoneNumber: encodeURIComponent(this.parameters.phoneNumber),
    }
  }

  private fetchAllDataAdjustment(resetPage?: boolean): void {
    if (resetPage) {
      this.parameters.page = 1
    }

    this.controller.getHistoryLogPoinUser(this.params)
  }

  get adjustmentAllData(): Array<DataObject[]> {
    if (this.controller.listHistoryLogPoinUser.length > 0) {
      return this.controller.listHistoryLogPoinUser.map(item => {
        return [
          {
            value: item.customerId || '-',
          },
          {
            value: item.phoneNumber ? Utils.countryIndonesiaPhoneNumber(item.phoneNumber, true) : '-',
          },
          {
            value: Utils.currencyDigit(Number(item.delta)),
          },
          {
            value: item.createdAt ? Utils.formatTimeZone(
              Utils.formatDate(item.createdAt, 'DD MMM YYYY[\n]HH:mm Z')
            ) : '-',
          },
          {
            value: item.requester || '-',
          },
          {
            value: item.approval || '-',
          },
          {
            value: Utils.toCapitalize(String(item.status)),
          },
          {
            value: `${item.customerId}-${item.id}`,
          },
        ]
      })
    }
    return []
  }

  private getType(status: string): string {
    if (status.toUpperCase() === AdjustmentStatus.REQUESTED) {
      return 'warning'
    } else if (status.toUpperCase() === AdjustmentStatus.APPROVED) {
      return 'success'
    } else if (status.toUpperCase() === AdjustmentStatus.REJECTED) {
      return 'error'
    }
    return 'warning'
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router
      .replace({
        query: val,
      })
      .catch(() => false)
  }
}
