import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule,
} from 'vuex-module-decorators'
import store from '@/app/ui/store'
import {
  AdjustmentParcelPoin,
  dataHistoryUploadBulkyParcelPoin,
  DetailAdjustmentParcelPoin,
  HistoryLogPoinUser,
  HistoryUploadBulkyParcelPoin,
  ListAdjustmentParcelPoin,
  ListHistoryLogPoinUser,
  UploadBulkyParcelPoin,
} from '@/domain/entities/AdjustmentParcelPoin'
import {
  EnumTransactionParcelPoinRequest,
  EnumTypeBalanceStatus,
  EventBus,
  EventBusConstants,
  LocalStorage,
  REQUEST_TOP_SALDO_PAGINATION,
  Utils,
} from '@/app/infrastructures/misc'
import { AdjustmentParcelPoinPresenter } from '@/app/ui/presenters/AdjustmentParcelPoinPresenter'
import { container } from 'tsyringe'
import { Pagination } from '@/domain/entities/Pagination'
import Vue from 'vue'
import {
  AdjustmentParcelPoinApprovingPoinRequest,
  AdjustmentParcelPoinRequest,
  ApproveRejectBulkyParcelPoinRequest,
} from '@/data/payload/api/AdjustmentParcelPoinRequest'
import Axios, { CancelTokenSource } from 'axios'
import { EnumStatusUpload } from '@/app/infrastructures/misc/Constants/upload'
import { UploadBulky } from '@/domain/entities/RequestTopupSaldo'
import { UploadRequest } from '@/data/payload/api/UploadRequest'

export interface AdjustmentParcelPoinState {
  isLoading: boolean
  listAdjustmentParcelPoin: AdjustmentParcelPoin[]
  paginationData: Pagination
  listHistoryLogPoinUser: HistoryLogPoinUser[]
  phoneNumber: string
  detailAdjustmentParcelPoin: DetailAdjustmentParcelPoin
}

export interface AdjustmentParcelPoinParamsInterface {
  page: number | string
  perPage: number | string
  phoneNumber?: string
  tab?: string
  sortBy?: string
  startDate?: string
  endDate?: string
  search?: string
  status?: string
}

export interface HistoryLogUserParamsInterface {
  page?: string | number
  perPage?: string | number
  customerId?: number
  sortBy?: string
}

export interface AdjustmentParcelPoinRequestState {
  customerId: number
  delta: number
  note: string
  pointType: EnumTransactionParcelPoinRequest
}

export interface AdjustmentParcelPoinApprovingState {
  pointId: number
  pointStatus: EnumTypeBalanceStatus
  reasonNote?: string
  username?: string
  actor?: string
}

export interface ApproveRejectBulkyParcelPoinFormState {
  pointStatus: EnumTypeBalanceStatus
  loyaltyPointHistoryIds: Array<number>
  reasonNote?: string
}

@Module({
  namespaced: true,
  store,
  name: 'adjustment-parcel-poin',
  dynamic: true,
})
class AdjustmentParcelPoinController extends VuexModule
  implements AdjustmentParcelPoinState {
  private presenter: AdjustmentParcelPoinPresenter = container.resolve(
    AdjustmentParcelPoinPresenter
  )
  public isLoading = false
  public listAdjustmentParcelPoin: AdjustmentParcelPoin[] = [
    new AdjustmentParcelPoin(),
  ]
  public paginationData: Pagination = new Pagination(
    1,
    REQUEST_TOP_SALDO_PAGINATION,
    0
  )
  public listHistoryLogPoinUser: HistoryLogPoinUser[] = [
    new HistoryLogPoinUser(),
  ]
  public phoneNumber = ''
  public detailAdjustmentParcelPoin: DetailAdjustmentParcelPoin = new DetailAdjustmentParcelPoin()
  public statusSuccessProcessBulkyRequestParcelPoin = ''
  public forceStart = false
  public cancelToken: CancelTokenSource | undefined = undefined
  public statusUploadBulky = EnumStatusUpload.START
  public errUploadBulky: null | string = null
  public responseUploadBulky: UploadBulkyParcelPoin | null = null
  public dataHistoryUploadBulkyParcelPoin: Array<dataHistoryUploadBulkyParcelPoin> = []
  public paginationHistoryUploadBulky: Pagination = new Pagination(1, REQUEST_TOP_SALDO_PAGINATION, 0)
  public dataRetry = NaN
  public statusApproveRejectBulkyPoin = ''
  public statusApproveRejectPoin = ''
  public statusRequestParcelPoin = ''

  @Action({ rawError: true })
  public getAll(params: AdjustmentParcelPoinParamsInterface): void {
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getAll(formattedParams)
      .then(res => {
        this.setListAdjustmentParcelPoin(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch List Adjustment Poin Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setListAdjustmentParcelPoin({
          data: [],
          pagination: {
            page: 1,
            perPage: REQUEST_TOP_SALDO_PAGINATION,
            totalItem: 0,
          },
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public postRequestPoin(form: AdjustmentParcelPoinRequestState): void {
    this.setLoading(true)
    this.presenter
      .postRequestPoin(
        new AdjustmentParcelPoinRequest(
          form.customerId,
          form.delta,
          form.note,
          form.pointType
        )
      )
      .then(() => {
        this.setStatusRequestParcelPoin(EventBusConstants.ADJUSTMENT_PARCEL_POIN_REQUEST_SUCCESS)
      })
      .catch(error => {
        Vue.notify({
          title: 'Request Adjustment Parcel Poin Failed',
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getHistoryLogPoinUser(params: HistoryLogUserParamsInterface): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getHistoryLogPoinUser(formattedParams)
      .then((res: ListHistoryLogPoinUser) => {
        this.setListHistoryLogPoinUser(res)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch List Adjustment Parcel Poin Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setListHistoryLogPoinUser({
          data: [],
          pagination: {
            page: 1,
            perPage: REQUEST_TOP_SALDO_PAGINATION,
            totalItem: 0,
          },
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getDetailAdjustmentParcelPoin(id: number): void {
    this.setLoading(true)
    this.presenter
      .getDetail(id)
      .then((result: DetailAdjustmentParcelPoin) => {
        this.setDetailAdjustmentParcelPoin(result)
      })
      .catch(error => {
        Vue.notify({
          title: 'Fetch Detail Adjustment Parcel Poin Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
        this.setDetailAdjustmentParcelPoin({})
      })
  }

  @Action({ rawError: true })
  public postApprovingPoin(form: AdjustmentParcelPoinApprovingState): void {
    this.setLoading(true)

    const actor = LocalStorage.getLocalStorage(
      LocalStorage.LOGIN_IDENTITY,
      true
    )

    this.presenter
      .postApproving(
        new AdjustmentParcelPoinApprovingPoinRequest(
          form.pointId,
          form.pointStatus,
          form.reasonNote,
          actor,
          actor
        )
      )
      .then(() => {
        if (form.pointStatus === EnumTypeBalanceStatus.APPROVED) {
          this.setStatusApproveRejectPoin(EventBusConstants.ADJUSTMENT_PARCEL_POIN_APPROVING_APPROVE_SUCCESS)
        } else {
          this.setStatusApproveRejectPoin(EventBusConstants.ADJUSTMENT_PARCEL_POIN_APPROVING_REJECT_SUCCESS)
        }
      })
      .catch(error => {
        if (form.pointStatus === EnumTypeBalanceStatus.APPROVED) {
          this.setStatusApproveRejectPoin(EventBusConstants.ADJUSTMENT_PARCEL_POIN_APPROVING_APPROVE_FAILED)
        } else {
          this.setStatusApproveRejectPoin(EventBusConstants.ADJUSTMENT_PARCEL_POIN_APPROVING_REJECT_FAILED)
        }
        Vue.notify({
          title: `Approving Parcel Poin Failed To ${Utils.toCapitalize(
            form.pointStatus
          )}`,
          text:
            error.status === 400 || error.status === 422
              ? error.error.message.en
              : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public uploadBulky(payload: { file: File }): void {
    this.setStatusUpload(EnumStatusUpload.PROCESSING)
    this.setErrUploadBulky(null)
    this.setRespUploadBulky(null)

    // set token source for cancel request
    this.cancelToken = Axios.CancelToken.source()

    this.presenter
      .uploadBulky(new UploadRequest(payload.file), this.cancelToken)
      .then(() => {
        this.setStatusUpload(EnumStatusUpload.COMPLETE)
        this.setErrUploadBulky(null)
      })
      .catch(error => {
        if (error.error.message.en.toLowerCase().includes('cancel')) {
          this.setStatusUpload(EnumStatusUpload.START)
        } else {
          this.setRespUploadBulky(null)
          this.setErrUploadBulky(error.error.message.id)
          this.setStatusUpload(EnumStatusUpload.FAILED)
        }
      })
  }

  @Action({ rawError: true })
  public cancelRequest(): void {
    if (this.cancelToken) {
      this.cancelToken.cancel()
    }
  }

  @Action({ rawError: true })
  public getHistoryUploadBulky(params: AdjustmentParcelPoinParamsInterface): void {
    this.setLoading(true)

    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )

    this.presenter
      .getHistoryUploadBulky(formattedParams)
      .then((res: HistoryUploadBulkyParcelPoin) => {
        this.setDataHistoryUploadBulky(res)
      })
      .catch((error) => {
        Vue.notify({
          title: 'Fetch List History Upload Bulky TopUp Saldo Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public getPreviewUploadBulky(params: AdjustmentParcelPoinParamsInterface): void {
    this.setRespUploadBulky(null)
    this.setLoading(true)
    const formattedParams = Utils.toInstance(
      new Map(),
      JSON.stringify(params),
      'snake_case'
    )
    this.presenter
      .previewUploadBulky(formattedParams)
      .then(res => {
        this.setRespUploadBulky(res)
      })
      .catch(error => {
        this.setRespUploadBulky(null)
        Vue.notify({
          title: 'Fetch List Preview Upload Bulky Topup Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public processBulkyRequestParcelPoin(): void {
    this.setLoading(true)

    this.presenter
      .processBulkyRequestParcelPoin()
      .then(() => {
        this.setStatusSuccessProcessBulkyRequestParcelPoin(EventBusConstants.PROCESS_UPLOAD_SALDO_BULKY_SUCCESS)
      })
      .catch(error => {
        if (error.status === 9) {
          this.setDatRetry(Number(error.error.message.id.split(' ')[1]) || 0)
          this.setStatusSuccessProcessBulkyRequestParcelPoin(EventBusConstants.PROCESS_UPLOAD_SALDO_BULKY_RETRY)
          return
        }
        if (error.status === 10) {
          this.setDatRetry(Number(error.error.message.id.split(' ')[1]) || 0)
          this.setStatusSuccessProcessBulkyRequestParcelPoin(EventBusConstants.PROCESS_UPLOAD_SALDO_BULKY_NOT_RETRY)
          return
        }
        Vue.notify({
          title: 'Send Bulky Upload Request Topup Failed',
          text: [400, 422].includes(error.status)
            ? error.error.message.en
            : 'Something wrong',
          type: 'error',
          duration: 5000,
        })
      })
      .finally(() => {
        this.setLoading(false)
      })
  }

  @Action({ rawError: true })
  public approveRejectBulkyParcelPoin(form: ApproveRejectBulkyParcelPoinFormState): void {
    this.setLoading(true)

    this.presenter
    .postApproveRejectBulkyParcelPoin(
      new ApproveRejectBulkyParcelPoinRequest(
        form.pointStatus,
        form.loyaltyPointHistoryIds,
        form.reasonNote
      )
    )
    .then(() => {
      if (form.pointStatus === EnumTypeBalanceStatus.APPROVED) {
        this.setStatusApproveRejectBulkyPoin(EventBusConstants.APPROVE_BULKY_PARCEL_POIN_SUCCESS)
      } else {
        this.setStatusApproveRejectBulkyPoin(EventBusConstants.REJECT_BULKY_PARCEL_POIN_SUCCESS)
      }
    })
    .catch((error) => {
      if (form.pointStatus === EnumTypeBalanceStatus.APPROVED) {
        this.setStatusApproveRejectBulkyPoin(EventBusConstants.APPROVE_BULKY_PARCEL_POIN_FAILED)
      } else {
        this.setStatusApproveRejectBulkyPoin(EventBusConstants.REJECT_BULKY_PARCEL_POIN_FAILED)
      }
      Vue.notify({
        title: `Failed to ${Utils.toCapitalize(form.pointStatus)} Bulky TopUp Saldo`,
        text: 
          error.status === 400 || error.status === 422
            ? error.error.message.en
            : 'Something wrong',
        type: 'error',
        duration: 5000,
      })
    })
    .finally(() => {
      this.setLoading(false);
    })
  }

  @Mutation
  private setLoading(bool: boolean): void {
    this.isLoading = bool
  }

  @Mutation
  private setListAdjustmentParcelPoin(res: ListAdjustmentParcelPoin): void {
    this.listAdjustmentParcelPoin = <AdjustmentParcelPoin[]>res.data
    this.paginationData = <Pagination>res.pagination
  }

  @Mutation
  private setListHistoryLogPoinUser(res: ListHistoryLogPoinUser): void {
    this.listHistoryLogPoinUser = <HistoryLogPoinUser[]>res.data
    this.phoneNumber = res.phoneNumber || ''
    this.paginationData = <Pagination>res.pagination
  }

  @Mutation
  private setDetailAdjustmentParcelPoin(res: DetailAdjustmentParcelPoin): void {
    this.detailAdjustmentParcelPoin = <DetailAdjustmentParcelPoin>res
  }

  @Mutation
  public setStatusUpload(enumStatus: EnumStatusUpload): void {
    this.statusUploadBulky = enumStatus
  }

  @Mutation
  public setForceStart(isForce: boolean): void {
    this.forceStart = isForce
  }

  @Mutation
  private setErrUploadBulky(err: null | string): void {
    this.errUploadBulky = err
  }

  @Mutation
  private setRespUploadBulky(val: UploadBulkyParcelPoin | null): void {
    this.responseUploadBulky = val
  }

  @Mutation
  public setStatusSuccessProcessBulkyRequestParcelPoin(status: string): void {
    this.statusSuccessProcessBulkyRequestParcelPoin = status
  }

  @Mutation
  public setDataHistoryUploadBulky(res: HistoryUploadBulkyParcelPoin): void {
    this.dataHistoryUploadBulkyParcelPoin = res.data
    this.paginationHistoryUploadBulky = res.pagination
  }

  @Mutation
  public setDatRetry(count: number): void {
    this.dataRetry = count
  }

  @Mutation
  public setStatusApproveRejectBulkyPoin(status: string): void {
    this.statusApproveRejectBulkyPoin = status
  }

  @Mutation
  public setStatusApproveRejectPoin(status: string): void {
    this.statusApproveRejectPoin = status
  }

  @Mutation
  public setStatusRequestParcelPoin(status: string): void {
    this.statusRequestParcelPoin = status
  }
}

export default getModule(AdjustmentParcelPoinController)
