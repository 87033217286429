import { EnumTransactionParcelPoinRequest } from '@/app/infrastructures/misc'
import {
  AdjustmentParcelPoinApproveRejectBulkyRequestInterface,
  AdjustmentParcelPoinApprovingPoinRequestInterface,
  AdjustmentParcelPoinRequestPoinRequestInterface,
} from '@/data/payload/contracts/AdjustmentParcelPoinRequest'
import { EnumTypeBalanceStatus } from '@/app/infrastructures/misc'

export class AdjustmentParcelPoinRequest
  implements AdjustmentParcelPoinRequestPoinRequestInterface {
  private customerId: number
  private delta: number
  private note: string
  private pointType: EnumTransactionParcelPoinRequest

  constructor(
    customerId: number,
    delta: number,
    note: string,
    poinType: EnumTransactionParcelPoinRequest
  ) {
    this.customerId = customerId
    this.delta = delta
    this.note = note
    this.pointType = poinType
  }

  toPayload(): string {
    const data = {
      customer_id: this.customerId,
      delta: this.delta,
      note: this.note,
      point_type: this.pointType,
    }

    return JSON.stringify(data)
  }
}

export class AdjustmentParcelPoinApprovingPoinRequest
  implements AdjustmentParcelPoinApprovingPoinRequestInterface {
  private pointId?: number
  private pointStatus?: EnumTypeBalanceStatus
  private reasonNote?: string
  private username?: string
  private actor?: string

  constructor(
    pointId?: number,
    pointStatus?: EnumTypeBalanceStatus,
    reasonNote?: string,
    username?: string,
    actor?: string
  ) {
    this.pointId = pointId
    this.pointStatus = pointStatus
    this.reasonNote = reasonNote
    this.username = username
    this.actor = actor
  }

  toPayload(): string {
    const data = {
      point_id: this.pointId,
      point_status: this.pointStatus,
      reason_note: this.reasonNote,
      username: this.username,
      actor: this.actor,
    }

    return JSON.stringify(data)
  }
}

export class ApproveRejectBulkyParcelPoinRequest implements AdjustmentParcelPoinApproveRejectBulkyRequestInterface {
  private pointStatus: EnumTypeBalanceStatus
  private loyaltyPointHistoryIds: Array<number>
  private reasonNote?: string

  constructor(
    pointStatus: EnumTypeBalanceStatus,
    loyaltyPointHistoryIds: Array<number>,
    reasonNote?: string,
  ) {
    this.pointStatus = pointStatus
    this.loyaltyPointHistoryIds = loyaltyPointHistoryIds
    this.reasonNote = reasonNote
  }

  toPayload(): string {
    const data = {
      point_status: this.pointStatus,
      loyalty_point_history_ids: this.loyaltyPointHistoryIds,
      reason_note: this.reasonNote,
    }

    return JSON.stringify(data)
  }
}
